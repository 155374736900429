import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/middleware/auth.global.ts";
import skeleton_45global from "/app/middleware/skeleton.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  skeleton_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "reset-itinerary-day-store": () => import("/app/middleware/reset-itinerary-day-store.ts")
}