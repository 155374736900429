<template>
  <select
    id="multiselect"
    v-model="selectedValue"
    multiple=""
    :data-hs-select="
      JSON.stringify({
        hasSearch: true,
        placeholder: props.context.attrs.placeholder,
        toggleCountTextMinItems: 4,
        toggleCountText: toggleCountText,
        placeholderClasses: 'text-red-500',
        toggleTag: '<button type=&quot;button&quot;></button>',
        searchClasses:
          'block w-full text-sm border-gray-200 rounded-lg focus:border-green-nomad-500 focus:ring-green-nomad-500 before:absolute before:inset-0 before:z-[1] dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 py-2 px-3',
        searchPlaceholder: searchPlaceholder,
        toggleClasses: 'mt-2.5 peer py-3 px-4 block w-full bg-gray-100 border-transparent rounded-lg text-sm text-left',
        dropdownClasses:
          'mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300',
        optionClasses:
          'py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800',
        optionTemplate:
          '<div class=&quot;flex justify-between items-center w-full&quot;><span data-title></span><span class=&quot;hidden hs-selected:block&quot;><svg class=&quot;flex-shrink-0 size-3.5 text-green-nomad-600&quot; xmlns=&quot;http:.w3.org/2000/svg&quot; width=&quot;24&quot; height=&quot;24&quot; viewBox=&quot;0 0 24 24&quot; fill=&quot;none&quot; stroke=&quot;currentColor&quot; stroke-width=&quot;2&quot; stroke-linecap=&quot;round&quot; stroke-linejoin=&quot;round&quot;><polyline points=&quot;20 6 9 17 4 12&quot;/></svg></span></div>',
        extraMarkup:
          '<div class=&quot;absolute top-1/2 end-3 -translate-y-1/2&quot;><svg class=&quot;flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-500&quot; xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;24&quot; height=&quot;24&quot; viewBox=&quot;0 0 24 24&quot; fill=&quot;none&quot; stroke=&quot;currentColor&quot; stroke-width=&quot;2&quot; stroke-linecap=&quot;round&quot; stroke-linejoin=&quot;round&quot;><path d=&quot;m7 15 5 5 5-5&quot;/><path d=&quot;m7 9 5-5 5 5&quot;/></svg></div>'
      })
    "
    class="hidden"
    @change="onChange(context._value)"
  >
    <option v-for="option in options" :key="option.id" :value="option.id" :selected="selectedValue && selectedValue.some((id) => id == option.id)">{{ option.label }}</option>
  </select>
</template>

<script setup lang="ts">
  import type { Ref } from 'vue';
  import { HSSelect, HSStaticMethods } from 'preline';

  interface SelectOption {
    id: number | string;
    label: string;
  }

  interface Context {
    attrs: {
      placeholder: string;
    };
    options: SelectOption[];
    searchPlaceholder: string;
    toggleCountText: string;
    _value: number[];
  }

  const props = defineProps<{ context: Context }>();

  const options = props.context.options;
  const searchPlaceholder = props.context.searchPlaceholder;
  const toggleCountText = props.context.toggleCountText;
  const selectedValue: Ref<number[]> = ref(props.context._value ? [...props.context._value] : []);

  // Preline vue issue : https://github.com/htmlstreamofficial/preline/issues/252#issuecomment-1895370061
  onMounted(async () => {
    setTimeout(() => {
      const el = HSSelect.getInstance('#multiselect') as HSSelect;
      if (el) {
        el.on('change', (val) => onChange(val));
      }
    }, 100);
  });

  const onChange = (value) => {
    props.context.node.input(value);
  };
</script>
