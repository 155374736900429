import { useGlobalLoadingStore } from '~/stores/common/GlobalLoadingStore';
export default defineNuxtRouteMiddleware(async (to, from) => {
  const globalLoadingStore = useGlobalLoadingStore();

  switch (to.path) {
    case '/planification':
      globalLoadingStore.setSkeleton('FullSkeletonItineraryWeek');
      break;
    case '/itineraries/days':
      if (to.query.itinerary) {
        globalLoadingStore.setSkeleton('FullSkeletonCurrentItineraryDay');
      } else {
        globalLoadingStore.setSkeleton('FullSkeletonItineraryDay');
      }
      break;
    default:
      globalLoadingStore.setSkeleton(null);
  }
});
