<template>
  <NuxtLayout>
    <div class="mx-auto bg-gray-100 min-h-screen px-36 py-10">
      <div>
        <h1 class="text-3xl font-bold text-gray-600">Erreur {{ error?.statusCode }}</h1>
        <h2>{{ error?.message }}</h2>

        <div class="size-2" />

        <p v-if="internalCode">
          Code : <strong>{{ internalCode }}</strong>
        </p>
      </div>

      <div class="size-6" />

      <button type="button" class="button button-white button-dark button-base" @click="handleError">Retour</button>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
  import { useGlobalLoadingStore } from '~/stores/common/GlobalLoadingStore';
  import type { NuxtError } from '#app';

  const props = defineProps({
    error: {
      type: Object as () => NuxtError,
      default: null
    }
  });

  const route = useRoute();
  const { setLoading } = useGlobalLoadingStore();

  onMounted(() => {
    setLoading(false);
  });

  const internalCode = computed(() => {
    const data = props.error?.data as string | null;

    if (!data) {
      return null;
    }

    try {
      return JSON.parse(data)['internalCode'];
    } catch (e) {
      return null;
    }
  });

  const handleError = () => clearError({ redirect: route.path.split('/').slice(0, -1).join('/') || '/' });
</script>
