import { defineStore } from 'pinia';
import type TransportOrganizerResource from '~/api/resource/TransportOrganizerResource';
import { DateTime } from 'luxon';

export const useTransportOrganizerStore = defineStore('transport_organizer', () => {
  const currentCookie = useCookie<TransportOrganizerResource | null>('transportOrganizer', {
    expires: DateTime.now().plus({ years: 1 }).toJSDate()
  });

  const currentTransportOrganizer = ref<TransportOrganizerResource | null>(currentCookie.value || null);

  async function set(resource: TransportOrganizerResource | null) {
    currentCookie.value = currentTransportOrganizer.value = resource;
    await nextTick();
  }

  function transportOrganizerCookieExist(): boolean {
    return currentCookie.value !== null && currentCookie.value !== undefined;
  }

  return { currentTransportOrganizer, set, transportOrganizerCookieExist };
});
