import { defineStore } from 'pinia';
import type UserResource from '~/api/resource/UserResource';
import { useApiConsumer } from '~/composables/useApiConsumer';
import { DateTime } from 'luxon';
import { useUserStore } from '~/stores/api/UserStore';
import { useTransportOrganizerStore } from '~/stores/api/TransportOrganizerStore';

interface AuthResponse {
  token: string;
  refresh_token: string;
}

export const useAuthStore = defineStore('auth', () => {
  const client = useApiConsumer();

  const userStore = useUserStore();
  const transportOrganizerStore = useTransportOrganizerStore();

  const expirationDate = DateTime.now().plus({ months: 1 }).toJSDate();

  const tokenCookie = useCookie<string | null>('authToken', {
    expires: expirationDate
  });
  const refreshTokenCookie = useCookie<string | null>('refreshToken', {
    expires: expirationDate
  });
  const impersonateCookie = useCookie<boolean | null>('isImpersonate', {
    expires: expirationDate
  });

  const token = ref<string | null>(tokenCookie.value || null);
  const refreshToken = ref<string | null>(refreshTokenCookie.value || null);
  const impersonate = ref<boolean | null>(Boolean(impersonateCookie.value) || null);

  /**
   * Checks if the user is authenticated.
   *
   * @returns {boolean} Returns true if the user is authenticated, otherwise false.
   */
  function isAuthenticated(): boolean {
    return !!userStore.user;
  }

  /**
   * Checks if the user is impersonated.
   *
   * @returns {boolean} Returns true if the user is impersonated, otherwise false.
   */
  function isImpersonate(): boolean {
    return !!impersonate.value;
  }

  /**
   * Checks if authentication can be done using the authentication token.
   *
   * @returns {boolean} True if an authentication token exists, false otherwise.
   */
  function canBeAuthenticate(): boolean {
    return !!token.value;
  }

  /**
   * Checks if a refresh token exists.
   *
   * @returns {boolean} True if a refresh token exists, false otherwise.
   */
  function canBeRefresh(): boolean {
    return !!refreshToken.value;
  }

  /**
   * Performs a login operation using the provided username and password.
   *
   * @param {string} username The username of the user trying to log in.
   * @param {string} password The password of the user trying to log in.
   *
   * @throws {ClientError} If the credentials are invalid.
   */
  async function login(username: string, password: string): Promise<void> {
    const response = await client.$post<AuthResponse>(`/security/login`, {
      email: username,
      password: password
    });

    setValues(response);
  }

  /**
   * Authenticates the user by making a GET request to the '/users/me' endpoint.
   */
  async function authenticate(): Promise<void> {
    try {
      const response = await client.$get<UserResource>(`/users/me`);

      await userStore.set(response);
    } catch (e) {
      console.error(e);

      await logout();
    }
  }

  /**
   * Refreshes the authentication token.
   */
  async function refresh(newRefreshToken: string | null = null): Promise<void> {
    try {
      const response = await client.$post<AuthResponse>(`/security/token/refresh`, {
        refresh_token: newRefreshToken || refreshToken.value
      });

      setValues(response);

      impersonateCookie.value = impersonate.value = !!newRefreshToken;
    } catch (e) {
      console.error(e);

      await logout();
    }
  }

  /**
   * Logout the user by resetting the relevant values to null.
   */
  async function logout() {
    token.value = null;
    refreshToken.value = null;

    tokenCookie.value = null;
    refreshTokenCookie.value = null;

    await userStore.set(null);
    await transportOrganizerStore.set(null);
  }

  async function resetPasswordRequest(email: string): Promise<void> {
    return await client.$post('/security/forgot-password/request', {
      email: email,
      callbackUrl: `${location.origin}/forgot-password/reset`
    });
  }

  async function resetPasswordAction(resetPasswordToken: string, password: string): Promise<void> {
    return await client.$post('/security/forgot-password/reset', {
      token: resetPasswordToken,
      password
    });
  }

  function setValues(response: AuthResponse): void {
    tokenCookie.value = token.value = response.token;
    refreshTokenCookie.value = refreshToken.value = response.refresh_token;
  }

  return {
    token,
    isAuthenticated,
    isImpersonate,
    canBeAuthenticate,
    canBeRefresh,
    login,
    logout,
    refresh,
    authenticate,
    resetPasswordRequest,
    resetPasswordAction
  };
});
