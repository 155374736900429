import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import axios_QMFgzss1s4 from "/app/plugins/axios.ts";
import preline_client_NKwNbVglRD from "/app/plugins/preline.client.ts";
import vue3_lottie_client_xXNcInN2H5 from "/app/plugins/vue3-lottie.client.ts";
import formkit_custom_input_client_GZBZPOF0vP from "/app/plugins/formkit-custom-input.client.ts";
import vue_final_modal_pML93k5qcp from "/app/plugins/vue-final-modal.ts";
import vue_select_8ji40gexSh from "/app/plugins/vue-select.ts";
import vue_toastificaton_client_LW56lxPlUl from "/app/plugins/vue-toastificaton.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  axios_QMFgzss1s4,
  preline_client_NKwNbVglRD,
  vue3_lottie_client_xXNcInN2H5,
  formkit_custom_input_client_GZBZPOF0vP,
  vue_final_modal_pML93k5qcp,
  vue_select_8ji40gexSh,
  vue_toastificaton_client_LW56lxPlUl
]