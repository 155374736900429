import { plugin, defaultConfig } from '@formkit/vue';
import { defineNuxtPlugin } from '#app';

// @ts-expect-error Do not understand why this append
import MultiSelect from '~/forms/common/MultiSelect.vue';

// @ts-expect-error Do not understand why this append
import VehicleSelect from '~/forms/common/VehicleSelect.vue';

// @ts-expect-error Do not understand why this append
import PoiSelect from '~/forms/common/PoiSelect.vue';

export default defineNuxtPlugin((nuxtApp) => {
  const multiselect = createInput(MultiSelect, {
    props: ['options', 'searchPlaceholder', 'toggleCountText']
  });

  const vehicleSelect = createInput(VehicleSelect, {
    props: ['options']
  });

  const poiSelect = createInput(PoiSelect, {
    props: ['options']
  });

  nuxtApp.vueApp.use(
    plugin,
    defaultConfig({
      inputs: {
        multiselect: multiselect,
        vehicleselect: vehicleSelect,
        poiselect: poiSelect
      }
    })
  );
});
