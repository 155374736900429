export const useGlobalLoadingStore = defineStore('globalLoading', {
  state: (): { loading: boolean; skeleton: null | string } => ({ loading: true, skeleton: null }),
  actions: {
    toggleLoading() {
      this.loading = !this.loading;
    },
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    setSkeleton(skeleton: string | null) {
      this.skeleton = skeleton;
    }
  }
});
