import { useAuthStore } from '~/stores/api/AuthStore';

const PUBLIC_URLS = ['/login', '/forgot-password/request', '/forgot-password/reset'];

export default defineNuxtRouteMiddleware(async (to, from) => {
  const authStore = useAuthStore();

  // If the user is impersonated, the temporary token must be refreshed.
  if (to.path === '/_switch_user') {
    const token = to.query.token as string | null;

    if (token) {
      await authStore.logout();

      await authStore.refresh(token);
    }
  }

  if (!authStore.isAuthenticated() && !PUBLIC_URLS.includes(to.path)) {
    return navigateTo('/login');
  }

  if (to.path === '/forgot-password/reset') {
    const token = to.query.token as string | null;

    if (!token) {
      return navigateTo('/login');
    }
  }

  if (authStore.isAuthenticated() && to.path === '/login') {
    return navigateTo('/structures');
  }
});
