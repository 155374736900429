<template>
  <Icon :name="props.name" :size="props.size" :customize="customize" mode="svg" />
</template>

<script setup lang="ts">
  const props = defineProps<{
    name: string;
    class: string;
    size?: string;
  }>();

  const customize = (content: string, name: string, prefix: string, provider: string) => {
    return content.replace(/fill="[^"]*"/g, `class="` + props.class + `"`);
  };
</script>
