import type { FormViolation } from '~/composables/useApiConsumer';

export class ApiError extends Error {
  constructor(message: string, violations: FormViolation[]) {
    super(message);

    this.code = '422';
    this.violations = violations;
  }

  code: string;
  violations: FormViolation[];
}
