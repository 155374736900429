import { defineStore } from 'pinia';
import type UserResource from '~/api/resource/UserResource';
import type TransportOrganizerResource from '~/api/resource/TransportOrganizerResource';
import { useTransportOrganizerStore } from '~/stores/api/TransportOrganizerStore';

export const useUserStore = defineStore('user', () => {
  const transportOrganizerStore = useTransportOrganizerStore();
  const user = ref<UserResource | null>(null);

  async function set(resource: UserResource | null) {
    user.value = resource;

    if (!transportOrganizerStore.transportOrganizerCookieExist()) {
      await transportOrganizerStore.set(resource?.transportOrganizers[0] as TransportOrganizerResource);
    }
  }

  return { user, set };
});
