<template>
  <VSelect
    v-model="selectedValue"
    :options="options"
    :filterable="false"
    :searchable="false"
    :clearable="false"
    :placeholder="context.attrs.placeholder"
    @option:selected="onChange"
  >
    <template #option="option">
      <div class="flex gap-2 items-center">
        <div class="text-sm flex-none w-52 truncate ...">{{ option.label }}</div>
        <div class="flex flex-wrap gap-0.5 grow">
          <div v-for="(configuration, index) of option.configurations" :key="index" class="badge badge-sm badge-white badge-dark badge-rounded tabular-nums gap-1">
            <div class="flex items-center text-xs gap-px">
              <OptiagoIcon class="fill-current" name="optiago:walking-person" />
              <span class="text-xxs">{{ configuration.seatNumber }}</span>
            </div>
            <div class="flex items-center text-xs gap-px">
              <OptiagoIcon class="fill-current" name="optiago:wheelchair" />
              <span class="text-xxs">{{ configuration.wheelChairNumber }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </VSelect>
</template>

<script setup lang="ts">
  import type { Ref } from 'vue';
  import VSelect from 'vue-select';
  import type VehicleOptionDTO from '~/dto/itinerary/Day/VehicleOptionDTO';
  import OptiagoIcon from '~/components/ui/OptiagoIcon.vue';

  interface Context {
    attrs: {
      placeholder: string;
    };
    options: VehicleOptionDTO[];
    _value: number;
  }

  const props = defineProps<{ context: Context }>();

  const options = props.context.options;
  const selectedValue: Ref<VehicleOptionDTO | null> = ref(null);
  updateSelectedValue();

  const onChange = (value) => {
    props.context.node.input(value.id);
  };

  function updateSelectedValue() {
    selectedValue.value = props.context._value
      ? options.find((opt) => {
          return opt.id == props.context._value;
        })!
      : null;
  }

  watch(props.context, () => {
    updateSelectedValue();
  });
</script>

<style scoped>
  >>> {
    --vs-colors--dark: #1f2937;
    --vs-colors--darkest: #1f2937;
    --vs-dropdown-option--active-color: #1f2937;
    --vs-controls-color: #6b7280;
    --vs-border-color: #f3f4f6;
    --vs-font-size: 14px;

    --vs-search-input-color: #6b7280;

    --vs-dropdown-option-padding: 3px 20px;
    --vs-dropdown-option--active-bg: #f3f4f6;
    --vs-controls-size: 0.75;
    --vs-border-radius: 0.5rem;
  }
</style>

<style>
  .vs__dropdown-toggle {
    padding-bottom: 12px;
  }

  .vs__search,
  .vs__search:focus,
  .vs__selected {
    padding-left: 16px;
  }

  .vs__selected-options {
    padding: 0;
  }

  .vs__search,
  .vs__actions,
  .vs__search:focus,
  .vs__selected {
    margin-top: 12px;
  }

  .vs__actions {
    padding-right: 16px;
  }

  .vs__selected {
    margin-left: 0;
  }

  .vs__dropdown-toggle {
    background-color: #f3f4f6;
  }

  .vs__dropdown-menu {
    margin-top: 8px;
    border-radius: 0.5rem;
    padding: 8px 4px;
  }

  .vs__dropdown-option:hover {
    background-color: #f3f4f6;
  }

  .vs__dropdown-option {
    border-radius: 0.5rem;
    padding: 8px 12px;
  }

  .vs__selected-options {
    font-size: 14px;
  }

  .vs--single.vs--open .vs__selected {
    opacity: 1 !important;
  }

  .vs--open .vs__dropdown-toggle {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .v-select {
    width: 100%;
  }
</style>
